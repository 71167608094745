<template>
  <div class="createCourse_div">
    <div class="mainBox">
      <div class="bread_top_nav_div" v-if="routeName == 'CreateRecordSet'">
        <span class="bread_top_nav_text">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>四名工作室</el-breadcrumb-item>
          <el-breadcrumb-item>
            <span class="bread_top_nav_item">{{ this.$route.query.id ? '修改' : '创建' }}录播课</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="min_box_div">
        <div class="main_box_div">
          <div class="top_title_box">{{ this.$route.query.id ? '修改' : '创建' }}录播课
            <span class="temp_class_span_div">-oef</span>
          </div>
          <CourseAdd> </CourseAdd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseAdd from "@/components/personChildPage/CourseAdd.vue";
export default {
  // name: "",
  data() {
    return {
      routeName: "",
    }
  },
  computed: {},

  components: {
    CourseAdd
  },

  methods: {
  },

  destroyed() {
  },

  mounted() {
  },

  created() {
    this.routeName = this.$route.name
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
  },
}
</script>

<style lang="less" scoped>
.createCourse_div {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;

  .mainBox {
    width: 1400px;
    margin: 0 auto;

    .bread_top_nav_div {
      width: 100%;
      margin: 0px;
      padding: 0px;
      line-height: 48px;
      height: 48px;
      display: flex;
      align-items: center;

      .bread_top_nav_text {
        font-size: 14px;
        color: #666;
      }

      .bread_top_nav_item {
        color: #ff8910 !important;
      }
    }
  }
}

.main_box_div {
  width: 100%;
  padding-bottom: 30px;
  margin-bottom: 30px;
  background-color: #fff;
  box-sizing: border-box;

  .top_title_box {
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    padding-left: 60px;
  }
}
</style>